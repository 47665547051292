<script setup lang="ts">
import { useInfoStore } from '~~/store/info';
import IconClose from '~~/assets/svg/icons/close.svg';
import IconList from '~~/assets/svg/icons/list.svg';
import IconUser from '~~/assets/svg/icons/user.svg';
import IconShvrCoin from '~~/assets/svg/icons/shvr-coin.svg';
import IconPlusSquare from '~~/assets/svg/icons/plus-square.svg';

const route = useRoute();
const infoStore = useInfoStore();
const drawerOpen = ref(false);
const showModal = ref(false);
const links = [
  { label: 'Event', to: '/event' },
  // { label: 'SHVR Coin', to: '/shvrcoin' },
  { label: 'Articles', to: '/articles' },
  { label: 'Gallery', to: '/gallery' },
];

watch(
  () => route.path,
  () => (drawerOpen.value = false)
);
</script>

<template>
  <header
    class="site-header flex w-full pr-body fixed top-0 z-2"
    :class="{ 'backdrop-blur': !drawerOpen && !infoStore.scrollOnTop }"
  >
    <Transition name="fade">
      <LayoutBG v-if="drawerOpen" />
    </Transition>

    <div class="container">
      <h1 class="hidden" v-if="$route.path === '/'">SHVR</h1>

      <div
        class="flex items-center gap-16 h-56 lg:h-88 border-0 border-b border-solid border-white/20"
      >
        <RouterLink class="mr-auto" to="/" aria-label="SHVR">
          <picture>
            <source
              media="(max-width: 1023px)"
              type="image/webp"
              :srcset="`
                ${$img('/assets/img/shvr.png', { height: 28, format: 'webp' })},
                ${$img('/assets/img/shvr.png', {
                  height: 56,
                  format: 'webp',
                })} 2x
              `"
            />
            <source
              type="image/webp"
              :srcset="`
                ${$img('/assets/img/shvr.png', { height: 48, format: 'webp' })},
                ${$img('/assets/img/shvr.png', {
                  height: 96,
                  format: 'webp',
                })} 2x
              `"
            />
            <source
              type="image/png"
              :srcset="$img('/assets/img/shvr.png', { height: 48 })"
            />
            <img
              alt="SHVR"
              width="110"
              height="28"
              :src="$img('/assets/img/shvr.png', { height: 28 })"
            />
          </picture>
        </RouterLink>

        <nav class="hidden lg:flex gap-24">
          <RouterLink
            custom
            v-for="(link, index) in links"
            v-slot="{ isActive, href, navigate }"
            :key="index"
            :to="link.to"
          >
            <a
              class="font-bold no-underline transition-opacity duration-300 hover:opacity-100"
              v-bind="$attrs"
              :class="
                isActive || $route.path.startsWith(`${href}/`)
                  ? 'opacity-100'
                  : 'opacity-50'
              "
              :href="href"
              @click="navigate"
            >
              {{ link.label }}
            </a>
          </RouterLink>
        </nav>

        <Transition name="fade">
          <div class="flex items-center gap-16" v-if="!drawerOpen">
            <span
              class="bg-white opacity-30 hidden lg:inline w-[1px] h-[15px]"
            />

            <RouterLink class="hidden lg:inline" to="/ggshiver">
              <picture>
                <source
                  type="image/webp"
                  :srcset="`
                    ${$img('/assets/img/gg-shiver.png', {
                      height: 40,
                      format: 'webp',
                    })},
                    ${$img('/assets/img/gg-shiver.png', {
                      height: 80,
                      format: 'webp',
                    })} 2x
                  `"
                />
                <img
                  alt="GG SHVR"
                  width="181"
                  height="40"
                  :src="$img('/assets/img/gg-shiver.png', { height: 40 })"
                />
              </picture>
            </RouterLink>

            <div class="flex gap-16" v-if="$auth.loggedIn">
              <!-- <button
                class="btn btn-sm btn-secondary btn-142x35 font-bold hidden lg:flex gap-8 px-12"
                @click="showModal = true"
              >
                <IconShvrCoin />
                {{
                  $auth.person
                    ? new Intl.NumberFormat().format(
                        $auth.person?.coinsAvailable
                      )
                    : 0
                }}
                Coins
                <IconPlusSquare />
              </button> -->
              <RouterLink
                class="btn btn-sm btn-outline-primary pt-7 px-9 pb-8 relative"
                to="/profile"
              >
                <IconUser />
                <span
                  class="indicator w-12 h-12 rounded-full absolute -top-6 -right-6"
                />
              </RouterLink>
            </div>
            <div class="flex gap-16" v-else>
              <!-- <button
                class="btn btn-sm btn-secondary btn-142x35 font-bold hidden lg:flex gap-8 px-12"
                @click="showModal = true"
              >
                <IconShvrCoin />
                Coins
                <IconPlusSquare />
              </button> -->
              <RouterLink class="btn btn-primary" to="/register">
                Sign Up
              </RouterLink>
            </div>
          </div>
        </Transition>

        <button
          class="btn btn-outline-primary bg-input-bg p-5 lg:hidden"
          aria-label="Toggle menu"
          @click="drawerOpen = !drawerOpen"
        >
          <Transition name="fade" mode="out-in">
            <IconClose
              class="text-primary"
              width="24"
              height="24"
              v-if="drawerOpen"
            />
            <IconList v-else />
          </Transition>
        </button>
      </div>
    </div>

    <Transition name="fade">
      <div class="absolute top-full inset-x-0" v-if="drawerOpen">
        <div class="container pt-24">
          <div class="flex gap-16 mb-24" v-if="$auth.loggedIn">
            <div class="grow">
              <div class="text-20 leading-32 font-bold">
                {{ $auth.person?.data?.name }}
              </div>
              <RouterLink class="text-14 leading-24" to="/profile/setting">
                Edit Profil
              </RouterLink>
            </div>
            <div class="btn btn-outline-primary relative">
              <IconUser width="21" height="26" />
              <span
                class="indicator w-12 h-12 rounded-full absolute -top-6 -right-6"
              />
            </div>
          </div>

          <!-- <button
            v-if="$auth.loggedIn"
            class="btn btn-sm btn-secondary btn-142x35 font-bold gap-8 px-12 py-8 mb-24"
            @click="showModal = true"
          >
            <IconShvrCoin />
            {{
              $auth.person
                ? new Intl.NumberFormat().format($auth.person?.coinsAvailable)
                : 0
            }}
            Coins
            <IconPlusSquare />
          </button>

          <button
            v-else
            class="btn btn-sm btn-secondary btn-142x35 font-bold gap-8 px-12 py-8 mb-24"
            @click="showModal = true"
          >
            <IconShvrCoin />
            Coins
            <IconPlusSquare />
          </button> -->

          <nav class="grid gap-12 text-24 leading-32 mb-24">
            <RouterLink
              class="font-bold no-underline transition-opacity duration-300 hover:opacity-100"
              v-for="(link, index) in links"
              :key="index"
              :to="link.to"
            >
              {{ link.label }}
            </RouterLink>

            <RouterLink to="/ggshiver">
              <picture>
                <source
                  type="image/webp"
                  :srcset="`
                    ${$img('/assets/img/gg-shiver.png', {
                      height: 48,
                      format: 'webp',
                    })},
                    ${$img('/assets/img/gg-shiver.png', {
                      height: 96,
                      format: 'webp',
                    })} 2x
                  `"
                />
                <img
                  alt="GG SHVR"
                  width="228"
                  height="48"
                  :src="$img('/assets/img/gg-shiver.png', { height: 48 })"
                />
              </picture>
            </RouterLink>
          </nav>

          <template v-if="!$auth.loggedIn">
            <h6 class="text-14 leading-24 font-black">Your Account</h6>
            <div class="grid grid-cols-2 gap-12">
              <RouterLink class="btn btn-primary btn-165x48" to="/register">
                Sign Up
              </RouterLink>
              <RouterLink class="btn btn-secondary btn-165x48" to="/login">
                Login
              </RouterLink>
            </div>
          </template>
        </div>
      </div>
    </Transition>

    <ClientOnly>
      <Teleport to="body">
        <ModalCoinsInfo v-model:show="showModal" v-if="showModal" />
      </Teleport>
    </ClientOnly>
  </header>
</template>

<style scoped lang="postcss">
.indicator {
  background: linear-gradient(180deg, #630000 20.31%, #d60000 100%);
  border: 1px solid #e80000;
}
</style>
